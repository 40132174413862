/* eslint-disable import/no-default-export */
import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Layout } from '../../components/Layout/Layout'
import {
  ContactContainer,
  ContactImage,
  ContactList,
  ContactNav,
  ItalicText,
} from '../../components/elements/contact'
import { ExternalYellow } from '../../components/elements/links/ExternalYellow'

const keywords = 'carina martins, contacto'
const description = 'contactos de carina martins'

const ContactPage = ({ location }: { location: Location }): JSX.Element => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "carina-martins-contacto.jpg" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      title={'Contact'}
      lang={'pt'}
      alternate="../../contact"
      description={description}
      keywords={keywords}
      location={location}
    >
      <ContactContainer>
        <ContactList>
          <p>
            <ItalicText>Entre em contacto </ItalicText>
            <br />
            <ExternalYellow linkTo="mailto:carinamrts@gmail.com?subject=Email from carinamartins.com">
              carinamrts[at]gmail.com
            </ExternalYellow>
          </p>
          <ContactNav>
            <ul>
              <p>
                <ItalicText>Seguir </ItalicText>
              </p>
              <li>
                <ExternalYellow linkTo="https://www.instagram.com/_carina_martins_/">
                  Instagram
                </ExternalYellow>
              </li>
              {/*
              <li>
                Vimeo{' '}
                <ExternalYellow linkTo="https://vimeo.com/carinamrts/">
                  &#64;carinamrts
                </ExternalYellow>
              </li>
              <li>
                Facebook{' '}
                <ExternalYellow linkTo="https://www.facebook.com/CarinaMrts/">
                  &#64;CarinaMrts
                </ExternalYellow>
              </li>
              <li>
                Twitter{' '}
                <ExternalYellow linkTo="https://twitter.com/_carinamartins_">
                  &#64;_carinamartins_
                </ExternalYellow>
              </li>
              */}
            </ul>
          </ContactNav>
        </ContactList>
        <ContactImage>
          <Img
            loading="eager"
            className="mainMenuImageContact"
            fluid={image.file.childImageSharp.fluid}
            alt="Carina Martins - contacto - floresta em Sintra"
          />
        </ContactImage>
      </ContactContainer>
    </Layout>
  )
}

export default ContactPage
